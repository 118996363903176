<template>
  <div class="logo_box">
    <i @click="onJumpHome()" class="logo"></i>
    <i class="icon_nav"></i>
  </div>
  <ul class="menu_ul">
    <!-- :class="{ active: currentPath === item.path }" -->
    <li @click="onSelect(item)" v-for="item of navList" :key="item.path">
      <p>{{ item.name }}</p>
      <i class="icon_right"></i>
    </li>
  </ul>
</template>
<script setup>
  import { ref, defineEmits, inject } from "vue";
  import { useSignOut } from "@/hooks/useSignOut";
  import { showConfirmDialog } from "vant";

  const currentPath = ref("");

  const profileData = inject("profileData");

  const emit = defineEmits(["change"]);

  const navList = ref([
    {
      name: "CEILING 首页",
      path: "/"
    },

    {
      name: "优惠劵",
      path: "/coupons"
    },
    {
      name: "个人中心",
      path: "/personal-center"
    },
    {
      name: "提现",
      path: "/withdrawal"
    },
    {
      name: "转币",
      path: "/currency-conversion"
    },
    {
      name: "退出",
      path: "/"
    }
  ]);

  const onJumpHome = () => {
    emit("change", "/");
  };

  const onSelect = (item) => {
    // 退出
    if (item.name === "退出") {
      onQuit();
    }
    currentPath.value = item.path;
    emit("change", item.path);
  };

  /** 退出 */
  const onQuit = () => {
    showConfirmDialog({
      message: "确认退出？"
    })
      .then(() => {
        useSignOut(profileData);
      })
      .catch(() => {
        // on cancel
      });
  };
</script>
<style lang="scss" scoped>
  .logo_box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 30px;
    .logo {
      display: flex;
      width: 220px;
      height: 54px;
      background-image: url("@/assets/images/side-bar/pic_logo@2x.png");
      background-size: 100% 100%;
    }
    .icon_nav {
      display: flex;
      width: 50px;
      height: 50px;
      background-image: url("@/assets/images/header/icon_gengduo@2x.png");
      background-size: 100% 100%;
    }
  }
  .menu_ul {
    display: flex;
    flex-direction: column;
    li {
      height: 100px;
      padding: 0 30px;
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-weight: 700;
      font-size: 28px;
      color: #fff;

      .icon_right {
        display: flex;
        width: 32px;
        height: 32px;
        background-image: url("@/assets/images/icon_jiantou@2x.png");
        background-size: 100% 100%;
      }
    }
    .active {
      color: #39d3f7;
    }
  }
</style>
