<template>
  <Header v-if="showHeader" />
  <div id="container">
    <router-view />
  </div>
</template>

<script setup>
  import Header from "@/components/header/header.vue";
  import { onMounted, provide, ref } from "vue";
  import { checkToken } from "@/api";
  import { getProfile } from "@/api/profile";
  import { getQueryVariable } from "@/utils/utils";

  const dcid = ref("");
  provide("dcid", dcid);

  const profileData = ref("");
  provide("profileData", profileData);

  const showHeader = ref(true);
  provide("showHeader", showHeader);

  const title = ref("");
  provide("title", title);

  onMounted(() => {
    let token = getQueryVariable().dcid;
    let code = getQueryVariable().code;
    if (window.location.href.indexOf("login") > -1 || window.location.href.indexOf("403") > -1) {
      return;
    }

    if (code) {
      localStorage.setItem("inventCode", code);
    }

    if (token) {
      localStorage.setItem("Authorization", token);

      checkToken().then((res) => {
        if (res) {
          localStorage.setItem("dcid", res.msg);
          getProfile({ dcid: res.msg }).then((res) => {
            if (res) {
              profileData.value = res;
              localStorage.setItem("profileData", JSON.stringify(res));
            }
          });
          // getAchieveNum({ discordId: res.msg }).then((res) => {
          //   if (res) {
          //     achieveNum.value = res;
          //   }
          // });
        }
      });
    } else {
      if (localStorage.getItem("profileData")) {
        profileData.value = JSON.parse(localStorage.getItem("profileData"));
        // getAchieveNum({ discordId: localStorage.getItem("dcid") }).then((res) => {
        //   if (res) {
        //     achieveNum.value = res;
        //   }
        // });
      }
    }

    if (getQueryVariable().action === "event") {
      document.title = "Celling";
    } else {
      document.title = "赛琳欧服陪玩";
    }
  });
</script>

<style lang="scss">
  html,
  body {
    width: 100%;
    height: 100%;
    font-size: 32px;
  }

  #app {
    color: #2c3e50;
    width: 100%;
    height: 100%;
  }

  #container {
    width: 100%;
    height: calc(100% - 98px);
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: -2px;
    background: #080808;
  }
</style>
