import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/home/index.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/index.vue")
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/register/index.vue")
  },
  {
    path: "/personal-center",
    name: "personal-center",
    component: () => import("../views/personal-center/index.vue")
  },
  {
    path: "/personal-data",
    name: "personal-data",
    component: () => import("../views/personal-data/index.vue")
  },
  {
    path: "/coupons",
    name: "coupons",
    component: () => import("../views/coupons/index.vue")
  },
  {
    path: "/withdrawal",
    name: "withdrawal",
    component: () => import("../views/withdrawal/index.vue")
  },
  {
    path: "/currency-conversion",
    name: "currency-conversion",
    component: () => import("../views/currency-conversion/index.vue")
  },
  {
    path: "/pw-home-page",
    name: "pw-home-page",
    component: () => import("../views/pw-home-page/index.vue")
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
