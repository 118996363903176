import axios from "axios";
import router from "@/router";
import { showToast } from "vant";
// export const baseUrl = "http://114.117.203.137:8082";
// export const baseUrl = "http://139.186.149.117:8082";
// export const baseUrl = "http://139.186.233.80:8082";

// export const baseUrl = "http://43.131.51.210";
export const baseUrl = "https://ceiling.gg:7788";
// export const baseUrl = window.location.origin + "/proxy/";

//创建axios实例
const request = axios.create({
  baseURL: baseUrl,
  // baseURL: window.location.origin+'/proxy/',
  timeout: 120000
});

// function createLoading() {
//   let loading = document.createElement("div");
//   loading.id = "loading";
//   loading.innerHTML = `
//     <img src="./loading.gif" alt=""/>
//     `;
//   document.body.appendChild(loading);
// }

// 移除loading
// function removeLoading() {
//   let loading = document.querySelector("#loading");
//   if (loading) {
//     document.body.removeChild(loading);
//   }
// }

//请求拦截
request.interceptors.request.use(
  (config) => {
    // removeLoading();
    // createLoading();
    config.headers = {
      "X-Ceiling-token": `${localStorage.getItem("Authorization") || ""}`
    };
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

//响应拦截
request.interceptors.response.use(
  (res) => {
    // removeLoading();
    if (res.data.code === 200) return res;
    else if (res.data.code === 401) {
      router.push("/login");
      localStorage.removeItem("Authorization");
      localStorage.removeItem("profileData");
      localStorage.removeItem("dcid");
    } else if (res.data.code === 403) {
      router.push("/403");
    } else {
      // store.dispatch("http/setHttpState", {
      //   state: false,
      //   dialogVisible: true,
      //   msg: res.data.msg
      // });
      console.error(res.data);
      showToast({ message: res.data.msg });
    }
  },
  (err) => {
    // removeLoading();
    // store.dispatch("http/setHttpState", {
    //   state: false,
    //   dialogVisible: true,
    //   msg: err.message
    // });
    showToast({ message: err.message });
    return Promise.reject(err);
  }
);

/**
 * 用于发送请求的通用方法
 * @param {*} data 配置信息，method请求方法，params参数，path路由
 * @returns
 */
async function http(data) {
  let result;
  if (data.method === "get" || data.method === "delete") {
    await request({
      url: data.path,
      method: data.method,
      params: data.params
    })
      .then((res) => {
        result = res.data.data ? res.data.data : res.data;
      })
      .catch(() => {
        console.log("err");
      });
  } else if (data.method === "post" || data.method === "put" || data.method === "patch") {
    await request({
      url: data.path,
      method: data.method,
      data: data.params
    })
      .then((res) => {
        result = res.data.data ? res.data.data : res.data;
      })
      .catch(() => {
        console.log("err");
      });
  }

  return result;
}

export default http;
