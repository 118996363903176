<template>
  <div class="header flex-sb-c">
    <img class="logo" src="@/assets/images/header/icon_logo@2x.png" alt="" @click="link('/')" />
    <div class="flex-s-c">
      <img @click="onOpenSide()" class="more" src="@/assets/images/header/icon_gengduo@2x.png" alt="" />
      <img v-if="!profileData.avatar" class="avatar" src="@/assets/images/header/default-avator.png" @click="link('/personal-center')" alt="" />
      <img @click="link('/personal-center')" v-else class="avatar" :src="profileData.avatar" alt="" />
    </div>
  </div>

  <SideMenuBar :isVisible="isVisibleSideBar" @change="sideChange">
    <MenuItem @change="pathChange" />
  </SideMenuBar>
</template>
<script setup>
  import SideMenuBar from "./SideMenuBar.vue";
  import MenuItem from "./MenuItem.vue";
  import { useRouter } from "vue-router";
  import { inject, ref } from "vue";

  const profileData = inject("profileData");
  const router = useRouter();

  const link = (path) => {
    router.push(path);
  };

  const isVisibleSideBar = ref(false);

  /** 打开侧边栏 */
  const onOpenSide = () => {
    isVisibleSideBar.value = true;
  };

  /** 侧边栏change */
  const sideChange = (value) => {
    isVisibleSideBar.value = value;
  };
  /** 切换路由 */
  const pathChange = (path) => {
    isVisibleSideBar.value = false;
    router.push(path);
  };
</script>
<style lang="scss" scoped>
  .header {
    width: 100%;
    height: 100px;
    background: #20202b;
    padding: 0 30px;
    box-sizing: border-box;

    .logo {
      width: 230px;
      height: auto;
    }

    .more {
      width: 50px;
      height: 50px;
      margin-right: 20px;
    }

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
</style>
