import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "amfe-flexible";
import Vant from "vant";
import "vant/lib/index.css";
import "@/assets/css/com.scss";
import { Lazyload, Uploader } from "vant";

const Vue = createApp(App);

Vue.use(Lazyload, {
  loading: require("@/assets/images/home/loading.jpg"), // 设置加载中的图片路径
  error: "" // 设置加载失败的图片路径
});

Vue.use(Uploader);

Vue.use(router).use(Vant).mount("#app");
