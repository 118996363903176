<template>
  <popup class="popup_box" v-model:show="showLeft" @closed="closed()" position="left">
    <slot></slot>
  </popup>
</template>
<script setup>
  import { Popup } from "vant";
  import { defineProps, onMounted, defineEmits, ref, watch } from "vue";

  const props = defineProps({
    isVisible: {
      type: Boolean,
      required: true
    },
    width: {
      type: String,
      default: "520"
    }
  });
  const emit = defineEmits(["change"]);

  const showLeft = ref(false);

  const closed = () => {
    emit("change", false);
  };

  watch(
    () => props.isVisible,
    (newValue) => {
      showLeft.value = newValue;
    }
  );

  onMounted(() => {
    showLeft.value = props.isVisible;
  });
</script>
<style lang="scss" scoped>
  .popup_box {
    width: 520px;
    height: 100%;
    background-color: #171b21;
  }
</style>
