import http from "@/utils/request.js";

// 校验token
export const checkToken = () => {
  return http({ path: `/web/users/checkToken`, method: "get" });
};

// 获取优质陪玩
export const qualityPlay = (params) => {
  return http({ path: `/web/ceiling/home/recommPlayers`, method: "get", params });
};

// 获取全部陪玩
export const allPlay = (params) => {
  return http({ path: `/web/ceiling/home/queryList`, method: "get", params });
};

/** 个人信息 */
export const profiles = (params) => {
  return http({ path: `/web/h5/user/profiles`, method: "get", params });
};

/** 保存个人资料 */
export const updateProfiles = (params) => {
  return http({ path: `/web/h5/user/updateProfiles`, method: "post", params });
};

/** 优惠劵 */
export const myVouchers = (params) => {
  return http({ path: `/web/h5/user/myVouchers`, method: "get", params });
};

/** 提现 */
export const createWebOrder = (params) => {
  return http({ path: `/web/withdrawl/createH5Order`, method: "post", params });
};
/** 转币 */
export const toCoin = (params) => {
  return http({ path: `/web/withdrawl/toCoin`, method: "post", params });
};
/** 转币 */
export const upload = (params) => {
  return http({ path: `/web/sidekick/common/upload`, method: "post", params });
};
/** 个人资料-上传头像 */
export const updateAvatar = (params) => {
  return http({ path: `/web/dc/v1/user/set/avatar`, method: "post", params });
};

/** 获取国家 */
export const getCountries = (params) => {
  return http({ path: `/web/dcUser/getCountries`, method: "get", params });
};
