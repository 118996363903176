import router from "@/router";

export const useSignOut = (profileData) => {
  localStorage.removeItem("Authorization");
  localStorage.removeItem("profileData");
  localStorage.removeItem("dcid");

  profileData.value = "";
  router.push("/");
};
