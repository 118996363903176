export const getQueryVariable = () => {
  let href = window.location.href;
  let query = href.substring(href.indexOf("?") + 1);
  let vars = query.split("&");
  let obj = {};
  for (var i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    obj[pair[0]] = pair[1];
  }
  return obj;
};
/** 时间戳转年月日
 * @param {Number} timestamp 时间戳 秒
 * @param {String} format 格式化字符串
 */
export function formatTimestamp(timestamp, format = "YYYY-MM-DD") {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  const formattedDate = format.replace("YYYY", year).replace("MM", month).replace("DD", day).replace("hh", hours).replace("mm", minutes).replace("ss", seconds);

  return formattedDate;
}

/** 年月日转凌晨时间戳秒 */
export function formatToTimestamp(date) {
  return new Date(date).setHours(0, 0, 0, 0) / 1000;
}
